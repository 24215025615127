import React from "react"
import { Flex, Box } from "rebass"
import Figure from "../components/Figure"
import { H1, H3, H4, P, Ul } from "../components/VerticalRhythm"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import RespGrid from "../components/RespGrid"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"
import imgObj06 from "../imgObjs/imgObj06"

const DataProtectionPage = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <Figure caption="">
      <PictureSet imgObj={imgObj06} />
    </Figure>
    <Section>
      <H1 mb={3}>Datenschutz</H1>
      <P>
        Vera Anders
        <br />
        Bettrather Str. 36
        <br />
        40160 Mönchengladbach
        <br />
        vera.anders@schreib-leicht.de
      </P>
      <P>
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
        berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
        über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
        abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
        Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
        das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
        IP-Adresse und der anfragende Provider.
      </P>
      <P>
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung
        von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7
        Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung
        zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
        jeweiligen Vorfalls von der Löschung ausgenommen.
      </P>
      <P>
        Diese Seite wird auf{" "}
        <a
          href="https://www.netlify.com/"
          rel="nofollow"
          rel="noreferrer"
          target="_blank"
        >
          https://www.netlify.com/
        </a>{" "}
        gehostet. Weitere Details zum Datenschutz von Netlify entnehmen Sie
        bitte{" "}
        <a
          href="https://www.netlify.com/gdpr/"
          rel="nofollow"
          rel="noreferrer"
          target="_blank"
        >
          https://www.netlify.com/gdpr/
        </a>
        . Vielen Dank.
      </P>
      <p>
        Auf dieser Website wird Google Analytics eingesetzt, ein
        Webanalysedienst der Google Ireland Limited (»Google«). Hierdurch ist es
        möglich, Daten, Sitzungen und Interaktionen über mehrere Geräte hinweg
        einer pseudonymen User-ID zuzuordnen und so die Aktivitäten eines
        Nutzers geräteübergreifend zu analysieren.
      </p>
      <p>
        Google Analytics verwendet sog. »Cookies«, Textdateien, die auf Ihrem
        Computer gespeichert werden und die eine Analyse der Benutzung der
        Website durch Sie ermöglichen. Die durch das Cookie erzeugten
        Informationen über Ihre Benutzung dieser Website werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert. Wir
        weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um eine
        IP-Anonymisierung erweitert wurde, um eine anonymisierte Erfassung von
        IP-Adressen (sog. IP-Masking) zu gewährleisten. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. Nähere Informationen zu
        Nutzungsbedingungen und Datenschutz finden Sie unter{" "}
        <a
          href="https://www.google.com/analytics/terms/de.html"
          target="_blank"
        >
          https://www.google.com/analytics/terms/de.html
        </a>{" "}
        bzw. unter{" "}
        <a href="https://policies.google.com/?hl=de" target="_blank">
          https://policies.google.com/?hl=de
        </a>
        .
      </p>
      <p>
        <strong>Zwecke der Verarbeitung</strong>
        <br />
        Im Auftrag des Betreibers dieser Website wird Google diese Informationen
        benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen.{" "}
      </p>{" "}
      <p>
        <strong>Rechtsgrundlage</strong>
        <br />
        Die Rechtsgrundlage für den Einsatz von Google Analytics ist Ihre
        Einwilligung gemäß{" "}
        <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank">
          Art. 6 Abs. 1 S.1 lit. a DSGVO
        </a>
        .
      </p>
      <p>
        <strong>Empfänger / Kategorien von Empfängern</strong>
        <br />
        Der Empfänger der erhobenen Daten ist Google.
      </p>
      <p>
        <strong>Übermittlung in Drittstaaten</strong>
        <br />
        Die personenbezogenen Daten werden unter dem EU-US Privacy Shield auf
        Grundlage des Angemessenheitsbeschlusses der Europäischen Kommission in
        die USA übermittelt. Das Zertifikat können Sie{" "}
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
          target="_blank"
        >
          hier
        </a>{" "}
        abrufen.
      </p>
      <p>
        <strong>Dauer der Datenspeicherung</strong>
        <br />
        Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID)
        oder Werbe-IDs verknüpften Daten werden laut Google nach 14 Monaten
        automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer
        erreicht ist, erfolgt automatisch einmal im Monat.
      </p>
      <p>
        <strong>Betroffenenrechte</strong>
        <br />
        Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft
        widerrufen, indem Sie die Speicherung der Cookies durch eine
        entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
        Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
        sämtliche Funktionen dieser Website vollumfänglich nutzen können.
      </p>
      <p>
        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
        und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
        IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem Sie das{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
        >
          Browser-Add-on
        </a>{" "}
        herunterladen und installieren. Opt-Out-Cookies verhindern die
        zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Um die
        Erfassung durch Universal Analytics über verschiedene Geräte hinweg zu
        verhindern, müssen Sie das Opt-Out auf allen genutzten Systemen
        durchführen. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt:{" "}
        <a href="javascript:gaOptout()">Google Analytics deaktivieren</a>.
      </p>
      <P>
        <em>
          Erstellt mit Datenschutz-Generator.de von RA Dr. jur. Thomas Schwenke
          und mit https://www.datenschutzbeauftragter-info.de/
        </em>
      </P>
    </Section>
  </Layout>
)

export default DataProtectionPage
